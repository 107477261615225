<template>
	<div class="student-place-qcm-container">
		<FullPageLoader v-if="isLoading"/>
		<div class="input-container">
			<label>
				<input type="checkbox" v-model="IsAnswersVisible">
				Afficher les reponses
			</label>
			
		</div>
		<div class="questions-container">
			<div class="question-container" v-for="question in qcm.questions" :key="question.id">
				<h2> <span v-html="question.question"> </span> <span>({{question.point }} point<span v-if="question.point > 1">s</span>)</span></h2>
				<QCMImage v-if="question.image" :image="question.image" />
				<div class="answers-container">
					<div v-for="answer in question.answers" :key="answer.id">
						<p> <input style="pointer-events: none;" type="checkbox" :checked="answer.is_correct && IsAnswersVisible" > <span v-html="answer.answer"></span></p> 
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import { getQCMService } from '../../../../../services/qcmServices'
import FullPageLoader from '../../../../Utils/FullPageLoader.vue'
import QCMImage from './QCMImage.vue'
export default {
	components: {
		FullPageLoader,
		QCMImage		
	},
	data() {
		return {
			qcm:{},
			questions:[],
			studentAnswers:[],
			isLoading: false,
			isPopUpImgVisible: false,
			selectedQuestion: null,
			IsAnswersVisible: false
		}
	},

	methods: {
		showImg(selectedQuestion){
			this.selectedQuestion = selectedQuestion
			this.isPopUpImgVisible = true
		},
		async getQcm() {
			const res = await getQCMService(this.$route.params.id)
			this.qcm = res

		},
		
	},
	created() {
		this.isLoading = true
		this.getQcm()
		this.isLoading = false
	},
	
}
</script>

<style scoped lang="scss">
@import '../../../../../assets/scss/student-place/qcm.scss';

.input-container{
	width: 60%;
	margin-inline: auto;
	margin-top: 4rem;
}
</style>
